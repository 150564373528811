import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuth0 } from "@auth0/auth0-react";

import { useSelector, useDispatch } from "react-redux";

import { auth0Params } from "./constants";
import { WrongList } from "./WrongList";

export const DialogIncorrect = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, user } = useAuth0();
  const dialogWrongOpen = useSelector((state) => state.dialogWrongOpen);
  const wrongQuestions = useSelector((state) => state.wrongQuestions);
  const handleAnswersClose = () => {
    dispatch({ type: "TOGGLE_INCORRECT_ANSWER" });
  };
  const handleResetTest = async () => {
    const accessToken = await getAccessTokenSilently(auth0Params);
    dispatch({ type: "FINAL_SCORE", accessToken, user });
  };

  return (
    <Dialog
      open={dialogWrongOpen}
      onClose={handleAnswersClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Incorrect Answers</DialogTitle>
      <DialogContent dividers>
        <WrongList wrongQuestions={wrongQuestions} />
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button onClick={handleResetTest} color="secondary">
          Reset Test
        </Button>
        <Button onClick={handleAnswersClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
