import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import questions from "./quiz.json";

export const WrongList = ({ wrongQuestions }) => {
  return (
    <List className="listWrapper">
      {wrongQuestions.map((questionId, i) => {
        const question = questions[questionId];
        return (
          <ListItem alignItems="flex-start" key={i}>
            <ListItemText
              primary={question.question}
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className="inline"
                    color="textPrimary"
                  >
                    {question.Ans}: {question[question.Ans]}
                  </Typography>
                  <br />
                  {question.Subject}: {question.Title}
                  <br />
                  Page {question.Feedback}: {question.Ahead}{" "}
                  {question.Objective}
                </>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};
