import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { WrongList } from "./WrongList";

export const DialogShowFinal = ({
  wrongList,
  dialogToggle,
  setDialogToggle,
}) => {
  const handleToggle = () => {
    setDialogToggle(!dialogToggle);
  };

  return (
    <Dialog
      open={dialogToggle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Incorrect Answers</DialogTitle>
      <DialogContent dividers>
        <WrongList wrongQuestions={wrongList} />
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button onClick={handleToggle} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
