import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { selectCurrentQuestion, selectCorrect } from "./selectors";
import { auth0Params, maxTest } from "./constants";

export const DialogAnswer = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();
  const question = useSelector(selectCurrentQuestion);
  const questionId = useSelector((state) => state.questionId);
  const dialogSubmitOpen = useSelector((state) => state.dialogSubmitOpen);
  const selectedAnswer = useSelector((state) => state.selectedAnswer);
  const questionCount = useSelector((state) => state.question);
  const numberCorrect = useSelector(selectCorrect);
  const isQuizOver = questionCount === maxTest - 1;

  const handleClose = async () => {
    const correct =
      selectedAnswer === question.Ans ? numberCorrect + 1 : numberCorrect;
    const wrongQuestion = selectedAnswer !== question.Ans && questionId;
    const accessToken = await getAccessTokenSilently(auth0Params);
    if (!isQuizOver) {
      dispatch({ type: "TOGGLE_ANSWER" });
    }
    dispatch({
      type: isQuizOver ? "FINAL_SCORE" : "NEXT_QUESTION",
      correct,
      wrongQuestion,
      accessToken,
      user,
    });
  };
  return (
    <Dialog
      open={dialogSubmitOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {selectedAnswer === question.Ans ? "Correct" : "Incorrect"}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          {selectedAnswer !== question.Ans && (
            <span>
              Correct Answer is {question.Ans}: {question[question.Ans]}
              <br />
            </span>
          )}
          {question.Subject}: {question.Title}
          <br />
          Page {question.Feedback}: {question.Ahead} {question.Objective}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {isQuizOver ? "Final score" : "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
