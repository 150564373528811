import React from "react";
import { createRoot } from "react-dom/client";
import LogRocket from "logrocket";
import { Provider } from "react-redux";
import { applyMiddleware, createStore, compose } from "redux";
import { Auth0Provider } from "@auth0/auth0-react";

import App from "./App";

import * as serviceWorker from "./serviceWorker";
import reducer from "./reducer";

LogRocket.init("jkkrpo/oec-test");

const middlewareEnhancer = applyMiddleware(LogRocket.reduxMiddleware());
const composeWithDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const container = document.getElementById("root");
const store = createStore(reducer, composeWithDevTools(middlewareEnhancer));
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Auth0Provider
    domain="drifterz28.auth0.com"
    clientId="0hfSXK4s78nbSqFJM92Abga2ViMeJsG7"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://drifterz28.auth0.com/api/v2/",
      scope: "read:current_user update:current_user_metadata openid profile",
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
