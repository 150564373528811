import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemButton from "@mui/material/ListItemButton";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { auth0Params } from "./constants";
import { useAuth0 } from "@auth0/auth0-react";
import { DialogShowFinal } from "./DialogShowFinal";
import { useState } from "react";

export const Scores = () => {
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  const [wrongQuestions, setWrongQuestions] = useState([]);
  const [dialogToggle, setDialogToggle] = useState(false);

  const pastScores = useSelector((state) => state.pastScores);
  const startQuiz = async () => {
    const accessToken = await getAccessTokenSilently(auth0Params);
    dispatch({ type: "START_QUIZ", accessToken, user });
  };
  const handleOpen = (wrongList) => {
    setWrongQuestions(wrongList);
    setDialogToggle(true);
  };
  return (
    <div>
      {!!pastScores.length && (
        <>
          <Typography>Past Scores</Typography>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {pastScores.map(({ score, date, wrongList }, i) => {
              const readableDate = new Date(date).toDateString();
              return (
                <ListItem key={i} disablePadding>
                  <ListItemButton onClick={() => handleOpen(wrongList)}>
                    <ListItemAvatar>
                      <Avatar sx={{ width: 56, height: 56, mr: 2 }}>
                        {score}%
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="View incorrect questions"
                      secondary={readableDate}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Button variant="contained" size="large" onClick={startQuiz}>
            Start a new Quiz
          </Button>
        </>
      )}
      {!pastScores.length && (
        <Button variant="contained" size="large" onClick={startQuiz}>
          Start Quiz
        </Button>
      )}
      <DialogShowFinal
        wrongList={wrongQuestions}
        dialogToggle={dialogToggle}
        setDialogToggle={setDialogToggle}
      />
    </div>
  );
};
