import questions from "./quiz.json";
import { saveState, shuffleArray } from "./helpers";
import { maxTest } from "./constants";

export const create =
  (reducers, initialState) =>
  (state = initialState, action = {}) => {
    const reducer = reducers[action.type];
    return reducer ? reducer(state, action) : state;
  };

const questionsKeys = Object.keys(questions);
shuffleArray(questionsKeys);

const questionList = questionsKeys.slice(0, maxTest);

const initialState = {
  question: 0,
  correct: 0,
  questionId: null,
  questionList: [],
  wrongQuestions: [],
  dialogSubmitOpen: false,
  dialogWrongOpen: false,
  selectedAnswer: "",
  pastScores: [],
  hasTestRunning: false,
};

const actions = {
  LOAD_USER_DATA(state, { userMetadata }) {
    return { ...state, ...userMetadata };
  },

  NEXT_QUESTION(state, { correct, wrongQuestion, accessToken, user }) {
    const nextQuestion = state.question + 1;
    const wrongQuestions = [...state.wrongQuestions];
    if (!!wrongQuestion) {
      wrongQuestions.push(wrongQuestion);
    }

    const newState = {
      ...state,
      question: nextQuestion,
      correct,
      questionId: state.questionList[nextQuestion],
      wrongQuestions,
      selectedAnswer: "",
    };

    saveState(newState, accessToken, user);
    return newState;
  },

  FINAL_SCORE(state, { correct, wrongQuestion, accessToken, user }) {
    const wrongQuestions = [...state.wrongQuestions];
    if (!!wrongQuestion) {
      wrongQuestions.push(wrongQuestion);
    }
    const correctAns = correct || state.correct;
    console.log(correctAns, state.correct);
    const pastScore = {
      date: Date.now(),
      score: ((correctAns / maxTest) * 100).toFixed(),
      wrongList: wrongQuestions,
    };

    const newState = {
      ...state,
      correct: correctAns,
      ...initialState,
      pastScores: [...state.pastScores, pastScore],
    };
    saveState(newState, accessToken, user);

    return newState;
  },

  START_QUIZ(state, { user, accessToken }) {
    const newState = {
      ...state,
      hasTestRunning: true,
      questionList: questionList,
      questionId: questionList[0],
    };
    saveState(newState, accessToken, user);
    return newState;
  },

  TOGGLE_ANSWER(state) {
    return { ...state, dialogSubmitOpen: !state.dialogSubmitOpen };
  },

  TOGGLE_INCORRECT_ANSWER(state) {
    return { ...state, dialogWrongOpen: !state.dialogWrongOpen };
  },

  SELECTED_ANSWER(state, { selectedAnswer }) {
    return { ...state, selectedAnswer };
  },
};

export default create(actions, initialState);
