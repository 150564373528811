import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Grid } from "@mui/material";

import { selectCurrentQuestion } from "./selectors";

export const Question = () => {
  const dispatch = useDispatch();

  const question = useSelector(selectCurrentQuestion);
  const selectedAnswer = useSelector((state) => state.selectedAnswer);

  const handleChange = (event) => {
    dispatch({ type: "SELECTED_ANSWER", selectedAnswer: event.target.value });
  };

  const handleClickAnswersOpen = () => {
    dispatch({ type: "TOGGLE_INCORRECT_ANSWER" });
  };

  const handleClickOpen = () => {
    dispatch({ type: "TOGGLE_ANSWER" });
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{question?.question?.trim()}</FormLabel>
      <RadioGroup
        name="question"
        value={selectedAnswer}
        onChange={handleChange}
      >
        <FormControlLabel
          value="A"
          control={<Radio />}
          label={question?.A?.trim()}
        />
        <FormControlLabel
          value="B"
          control={<Radio />}
          label={question?.B?.trim()}
        />
        <FormControlLabel
          value="C"
          control={<Radio />}
          label={question?.C?.trim()}
        />
        <FormControlLabel
          value="D"
          control={<Radio />}
          label={question?.D?.trim()}
        />
      </RadioGroup>
      <Grid>
        <Button
          disabled={selectedAnswer.length !== 1}
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClickAnswersOpen}
          style={{ float: "right" }}
        >
          List incorrect Answers
        </Button>
      </Grid>
    </FormControl>
  );
};
