import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogRocket from "logrocket";
import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";

import "./index.css";
import { Question } from "./Question";
import { Score } from "./Score";
import { DialogAnswer } from "./DialogAnswer";
import { DialogIncorrect } from "./DialogIncorrect";
import { Footer } from "./Footer";
import { Login } from "./Login";
import { Logout } from "./Logout";
import { useUserMetadata } from "./hooks";
import Loading from "./Loading";
import { Scores } from "./Scores";

function App() {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const dispatch = useDispatch();
  const userMetadata = useUserMetadata();
  const hasTestRunning = useSelector((state) => state.hasTestRunning);

  useEffect(() => {
    LogRocket.identify(user?.sub, {
      name: user?.name,
    });
    dispatch({ type: "LOAD_USER_DATA", userMetadata });
  }, [user, userMetadata, dispatch]);

  return (
    <div className="App-body">
      <Container maxWidth="sm">
        {isLoading && <Loading />}
        {!isLoading && isAuthenticated && (
          <>
            {!hasTestRunning && <Scores />}
            {hasTestRunning && (
              <>
                <Score />
                <Question />
                <DialogAnswer />
                <DialogIncorrect />
              </>
            )}
          </>
        )}
        {!isLoading && !isAuthenticated && <Login />}
      </Container>
      <Footer />
      {!isLoading && isAuthenticated && <Logout />}
    </div>
  );
}

export default App;
