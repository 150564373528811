import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { maxTest } from "./constants";

export const Score = () => {
  const score = useSelector((state) => state.correct);
  const question = useSelector((state) => state.question);

  return (
    <Grid container className="score">
      <Grid container item xs={4}>
        Answered: {question}
      </Grid>
      <Grid container item xs={4}>
        Correct: {score}
      </Grid>
      <Grid container item xs={4}>
        Score: {score !== 0 ? `${((score / maxTest) * 100).toFixed()}%` : 0}
      </Grid>
    </Grid>
  );
};
