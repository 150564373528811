import { domain } from "./constants";

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export const saveState = async (state, accessToken, user) => {
  const raw = JSON.stringify({
    user_metadata: state,
  });
  try {
    const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
    await fetch(userDetailsByIdUrl, {
      method: "PATCH",
      body: raw,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    console.log(e.message);
  }
};
